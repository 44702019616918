:root {
  --color-bg: #ffffff;
  --color-text-faint: #737373;
  --color-detail: red;
}

html,
body,
#root {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Akkurat Pro", sans-serif;
  background: var(--color-bg);
}
