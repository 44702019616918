@charset "UTF-8";
/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * kulturit.no
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2015
 *
 */

@font-face {
  font-family: "Akkurat Pro";
  font-weight: normal;
  font-style: normal;
  src: url("https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-regular.eot");
  src: url("https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-regular.eot?#iefix")
      format("embedded-opentype"),
    url("https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-regular.woff")
      format("woff");
}

@font-face {
  font-family: "Akkurat Pro";
  font-weight: bold;
  font-style: normal;
  src: url("https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-bold.eot");
  src: url("https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-bold.eot?#iefix")
      format("embedded-opentype"),
    url("https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-bold.woff") format("woff");
}

@font-face {
  font-family: "Akkurat Pro";
  font-weight: normal;
  font-style: italic;
  src: url("//frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-Italic.eot");
  src: url("//frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("//frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-Italic.woff") format("woff");
}

@font-face {
  font-family: "Akkurat Pro";
  font-weight: bold;
  font-style: italic;
  src: url("//frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-boldItalic.eot");
  src: url("//frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-boldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("//frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-boldItalic.woff") format("woff");
}
